import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { blue } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import { Tween } from 'react-gsap';
import { CirclePicker } from 'react-color'

import vibeIcon from '../media/vibe-icon.svg';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  Vibe: {
    position: 'fixed',
    bottom: 0,
    left: '50%',
    transform: 'translateY(-30px) translateX(-28px)',
    zIndex: 99999999999
  },
  VibeButton: {
    background:"#fff",
    objectFit: "cover"

  },
  VibeIcon: {
        color: "000"
  }
});

function VibeDialog(props) {
  const { onClose, getLocation, newVibe, open, setVibe, vibe, vibeColor, vibeText, locationEnabled } = props;

  const allowLocation = () => {
    getLocation();
  }

  const createVibe = () => {
    newVibe();
    handleClose()
  }

  const updateVibe = (event) => {
    event.stopPropagation();
    setVibe(event.currentTarget.value);
  }

  const handleClose = () => {
    onClose();
  };



  return (
    <Dialog onClose={handleClose} aria-labelledby="vibes-dialog-title" open={open} className="vibes-container">
      <DialogTitle id="vibes-dialog-title">
        <Box className="vibes-header-text-container">
          <Typography variant="h6" component="h6" className="vibes-header-text" style={{flexGrow: 1}}>
            What's the vibe?
          </Typography>
        </Box>
      </DialogTitle>

      {!locationEnabled && <Box className="vibes-location">
        <Box className="vibes-icons">
        <Typography variant="body2" component="p" className="vibes-header-text-items">
        Location services must be enabled.
        </Typography></Box>
        <Button onClick={allowLocation} className="vibe-button-location">
          Enable Location Services
        </Button>
      </Box>
      }

      {locationEnabled && <Box className="vibes-picker">
        <Box className="vibes-icons">
          <Tween from={{ opacity: '0', scale: 0}} stagger={0.2} ease="elastic.out(0.2, 0.1)">

            <IconButton value="1" onClick={event => updateVibe(event)} className={vibe === "1" ? "vibes-icon-button vibe-1" : "vibes-icon-button" }>
              <SentimentVeryDissatisfiedIcon />
            </IconButton>

            <IconButton value="2" onClick={event => updateVibe(event)} className={vibe === "2" ? "vibes-icon-button vibe-2" : "vibes-icon-button" }>
              <SentimentDissatisfiedIcon />
            </IconButton>

            <IconButton value="3" onClick={event => updateVibe(event)} className={vibe === "3" ? "vibes-icon-button vibe-3" : "vibes-icon-button" }>
              <SentimentSatisfiedIcon />
            </IconButton>

            <IconButton value="4" onClick={event => updateVibe(event)} className={vibe === "4" ? "vibes-icon-button vibe-4" : "vibes-icon-button" }>
              <SentimentSatisfiedAltIcon />
            </IconButton>


            <IconButton value="5" onClick={event => updateVibe(event)} className={vibe === "5" ? "vibes-icon-button vibe-5" : "vibes-icon-button" }>
              <SentimentVerySatisfiedIcon />
            </IconButton>

            <IconButton value="0" onClick={event => updateVibe(event)} style={vibe === "0" ? {color: vibeColor} : {color: "none"} } className={vibe === "0" ? "vibes-icon-button vibe-0" : "vibes-icon-button" }>
              <AddCircleOutlineIcon />
            </IconButton>
          </Tween>
        </Box>
        {vibe === "0" && <Box className="vibe-custom">
          <CirclePicker
            className="vibe-custom-picker"
            name='color'
            defaultValue='#efefef'
            // value={this.state.color} - for controlled component
            onChange={color => console.log(color)}
          />
          <TextField
          id="vibe-custom-text"
          className="vibe-custom-text"
          variant="outlined"
          placeholder="#vibe"
        />
        </Box>}
        <Button onClick={createVibe} className={'vibe-button-add vibe-'+vibe}>
          Share the Vibe
        </Button>
      </Box>}



    </Dialog>
  );
}

VibeDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function SimpleDialogDemo(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    props.getLocation();
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  function VibeIcon(props) {
  return (
    <Icon {...props} className="VibeIcon">
      <img fill="black" src={vibeIcon} alt="Vibe" />
    </Icon>
  );
}

  return (
    <div className={classes.Vibe}>
    <Fab aria-label="add" className={classes.VibeButton + " VibeButton"} onClick={handleClickOpen}>
      <VibeIcon />
    </Fab>
      <VibeDialog open={open} onClose={handleClose} locationEnabled={props.locationEnabled} getLocation={props.getLocation} newVibe={props.newVibe} setVibe={props.setVibe} vibe={props.vibe} vibeColor={props.vibeColor} vibeText={props.vibeText}/>
    </div>
  );
}
