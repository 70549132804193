const config = require('../config.js')

const { post, get } = require('./services/vibe-api-service.js')

const getVibeList = async function() /* : Promise<event[] | void> */ {

  // logic to limit date range
  // let now = new Date();
  // now.setMonth(now.getMonth() - 1);
  // let start = now.toISOString();
  // var later = new Date();
  // let finish = later.toISOString();

  try {
    const response = await get(config.API_PATH)
    if (
      response &&
      response.data &&
      Array.isArray(response.data)
    ) {
      return response.data;
    }
  } catch (error) {
    console.log(
      'An error has occurred attempting to load the vibes.',
      error.Error || error
    )
  }
}

const addVibe = async function(payload) /* : Promise<event[] | void> */ {
  try {
    const response = await post(config.API_PATH, JSON.stringify(payload) )

    if (
      response &&
      response.status &&
      response.status === 201
    ) {
      console.log("Vibe added: ", response);
    }
  } catch (error) {
    console.log(
      'An error has occurred attempting to add your vibe.',
      error.Error || error
    )
  }
}

export {
  addVibe,
  getVibeList
}
