import React, { Component } from 'react';
import BottomAppBar from './components/BottomAppBar.js';
import VibeDialog from './components/VibeDialog.js';
import VibeDialogEmoji from './components/VibeDialog-Emoji.js';
import Map from './components/Map.js';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import VibeIcon from './media/vibe-icon.png';
import Spinner from './media/spinner.gif';
import Moment from 'react-moment';
import './App.css';

const moment = require('moment');

const { getVibeList, addVibe } = require('./lib/vibes.js');



class App extends Component {
  constructor(props) {
    super(props);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.setState = this.setState.bind(this);
    this.getVibes = this.getVibes.bind(this);
    this.setVibe = this.setVibe.bind(this);
    this.newVibe = this.newVibe.bind(this);
    this.setMapHeight = this.setMapHeight.bind(this);
    this.findRecentPosts = this.findRecentPosts.bind(this);

    this.state = {
      user: "1",
      vibe: "",
      vibeText: "",
      vibeColor: "",
      isLocating: false,
      locationEnabled: false,
      longitude: "",
      latitude: "",
      vibes: [],
      center: {
        lat: 39.1836,
        lng: -96.5717
      }
    }
  }


  // logic
  handleOpen = () => {
    this.setState({ contentItemLoaded: true })
    console.log("Vibe dialog opened")
  };

  handleClose = () => {
    this.setState({ contentItemLoaded: false })
    console.log("Vibe dialog closed.")
  };

  setMapHeight = () => {

  }

  findRecentPosts = (date) => {
    var now = new Date();
    var limit = now.setDate(now.getDate() - 1)
    return moment(date).isAfter(limit);
  }

 getVibes = async function(
  req /* : BmRequest */,
  res /* : BmResponse */
) /* : Promise<BmResponse> */ {
  const vibes = await getVibeList()
  if (Array.isArray(vibes)) {
    let markers = vibes.map (item => {
      let elItem = {
        title: item.vibeText ? item.vibeText : "",
        locLat: item.location.latitude ? item.location.latitude : 0,
        locLong: item.location.longitude ? item.location.longitude : 0,
        user: item.user ? item.user : "0",
        image: item.image ? item.image : "",
        vibe: item.vibe ? item.vibe : "",
        vibeText: item.vibeText ? item.vibeText : "",
        vibeColor: item.vibeColor ? item.vibeColor : "",
        id: item.vibeId ? item.vibeId : "0",
        recent: this.findRecentPosts(item.created)
      }
      return elItem;
    })

    this.setState({ vibes: markers }, () => {
      console.log("%cVibes loaded", 'color: #3d3d3d; background: rgba(232,69,160,1);', this.state.vibes )
    });
    }
  }

  setVibe = (id) => {
    this.setState({
      vibe: id
    }, () => {
      console.log("%cVibe set:", 'color: #3d3d3d; background: rgba(77,217,120,1);', this.state.vibe )
    })
  }

  setLocation = (pos) => {
      var crd = pos.coords;

      this.setState({
        longitude: crd.longitude,
        latitude: crd.latitude,
        center: {
          lat: crd.latitude,
          lng: crd.longitude,
        },
        isLocating: false,
        locationEnabled: true
      }, () => {
        console.log("%cLocation acquired:", 'color: #3d3d3d; background: rgba(228,147,77,1);', this.state )
      });
  };

  getLocation = () => {
    this.setState({
      isLocating: true
    }, () => {
        navigator.geolocation.getCurrentPosition(this.setLocation);
    })
  }

  newVibe = async function () {
    const vibeData = {
        "location": {
            "latitude": this.state.latitude,
            "longitude": this.state.longitude
        },
        "vibe": this.state.vibe,
        "vibeText": this.state.vibeText ? this.state.vibeText : "",
        "vibeColor" : this.state.vibeColor ? this.state.vibeColor : "none",
        "user": this.state.user ? this.state.user : '0',
        "image": "https://vibe.adamelliott.com/icons/favicon.png"
      }

    await addVibe(vibeData);
    this.getVibes();
  }

  // component did mount
  componentWillMount() {

  }
  componentDidMount() {
      this.getLocation();
      this.getVibes();
    }

  // render

  render() {
    this.setMapHeight()
    return (
      <div className="App">
        <VibeDialogEmoji
          getLocation={() => this.getLocation}
          latitude={this.state.latitude}
          longitude={this.state.longitude}
          locationEnabled={this.state.locationEnabled}
          vibe={this.state.vibe}
          newVibe={this.newVibe}
          setVibe={this.setVibe}
          vibeColor={this.state.vibeColor}
          vibeText={this.state.vibeText}
          />

          {!this.state.locationEnabled && <Box className="vibes-location-wall">
            <Box className="vibes-location">
            <img style={{width: "200px", textAlign: "center", margin: "0 auto", opacity: ".25"}} src={VibeIcon} alt="Vibe logo" />
            <Box className="vibes-icons">
            <Typography variant="body2" component="p" className="vibes-header-text-items">
            Location services must be enabled to Vibe.
            </Typography></Box>
            <Button onClick={this.getLocation} className="vibe-button-location">
              Find My Location
            </Button>
          </Box>
          </Box>

          }
          {!this.state.locationEnabled && <Box>
          {this.state.isLocating && <Box className="vibes-location-wall">
              <Box className="vibes-location">
              <img src={Spinner} alt="" />
              <Typography variant="body2" component="p" className="vibes-header-text-items">
                Finding your location...
              </Typography>
              </Box>
            </Box>
          }
          </Box>}



        {this.state.locationEnabled && <Map
          locationEnabled={this.state.locationEnabled}
          latitude={this.state.latitude}
          longitude={this.state.longitude}
          center={this.state.center}
          getVibes={() => this.getVibes}
          vibes={this.state.vibes}
        />}

        <BottomAppBar
          getLocation={() => this.getLocation}
          locationEnabled={this.state.locationEnabled}
          getVibes={this.getVibes}
          locationEnabled={this.state.locationEnabled}
        />

      </div>
    );
  }

}

export default App;
