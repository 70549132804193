import React from 'react';
import GoogleMapReact from 'google-map-react';
import MapPin from './MapPin.js'
import { Tween } from 'react-gsap';

export default class Map extends React.Component {
   constructor(props) {
     super(props);
     this.props = props;

     this.state = {
       markers: [],
       isMarkerShown: false,
       defaultProps: {
         center: {
           lat: 39.1836,
           lng: -96.5717
         },
         zoom: 4
       }
     }
   }

  getInitialState() {
    return { markers: [], isMarkerShown: false, };
  }



  componentDidMount() {
    this.delayedShowMarker()
    this.props.getVibes();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.locationEnabled !== nextProps.locationEnabled) {
      return true;
    }
    if (this.props.center !== nextProps.center) {
      return true;
    }
    if (this.props.center === nextProps.center) {
      return true;
    }
    return false;
  }

  delayedShowMarker = () => {
    setTimeout(() => {
      this.setState({ isMarkerShown: true })
    }, 3000)
  }

  handleMarkerClick = () => {
    this.setState({ isMarkerShown: false })
    this.delayedShowMarker()
  }

  render(props) {
    const mapOptions = {
      gestureHandling: 'greedy',
      zoom: 14,
      styles: [{
        "featureType": "road",
        "elementType": "labels",
        "stylers": [{
          "visibility": "on"
        }]
      },
      {
        "featureType": "poi",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "featureType": "administrative",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [{
            "color": ""
          },
          {
            "weight": .5
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [{
            "color": ""
          },
          {
            "weight": 0.1
          }
        ]
      },
      {
        "featureType": "landscape",
        "stylers": [{
          "color": ""
        }]
      },
      {
        "featureType": "water",
        "stylers": [{
          "visibility": "on"
        }]
      },
      {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [{
        "color": "#cce4ff"
      }]
      },
      {
        "featureType": "transit",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "elementType": "labels",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "elementType": "labels.text",
        "stylers": [{
          "visibility": "on"
        }]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [{
          "color": "#ffffff"
        }]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#000000"
        }]
      },
      {
        "elementType": "labels.icon",
        "stylers": [{
          "visibility": "on"
        }]
      }
    ]
    }

    return (
      <div>
      <Tween from={{ opacity: '0' }} duration={1} ease="power4">
        <div style={{ height: 'calc(100vh - 56px)', width: '100%' }}>
          <GoogleMapReact
            //bootstrapURLKeys={{ key: "AIzaSyAy0B1yQwhZ75dUUlLqPwJOm3tC67wnP30" }}
            bootstrapURLKeys={{ key: "AIzaSyBe77Gu3GhyZtfj7bX-MPeK4Lc3SqmeHTQ" }}
            center={this.props.center}
            defaultZoom={mapOptions.zoom}
            hoverDistance={30}
            ref={(ref) => {
              this.mapRef = ref;
            }}
            options={mapOptions}
          >

          {this.props.vibes.map(pin =>
            <MapPin
              lat={pin.locLat}
              lng={pin.locLong}
              vibe={pin.vibe}
              user={pin.user}
              key={pin.id}
              id={pin.id}
              isRecent={pin.recent}
              />
          )}

          </GoogleMapReact>
        </div>
        </Tween>
     </div>


    );
  }
}
