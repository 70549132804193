import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import HelpIcon from '@material-ui/icons/Help';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Tween } from 'react-gsap';
// import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    background: 'white',
  },
  grow: {
    flexGrow: 1,
  },
  icon: {
    color: "#3d3d3d"
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
}));

export default function BottomAppBar(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      {props.locationEnabled &&
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar>
          <Tween from={{ opacity: '0', scale: 0, x:-1000}} delay={.6} ease="power4">
            <IconButton edge="start" color="inherit" aria-label="get vibes" onClick={props.getVibes}>
              <RefreshIcon className={classes.icon}/>
            </IconButton>
          </Tween>
              <div className={classes.grow} />
        </Toolbar>
      </AppBar>}
    </React.Fragment>
  );
}
