import React from 'react';

const MapPin = props => {
  console.log(props)
  return (
        <div key={props.id} className={ props.isRecent ? "vibe vibe-recent vibe-" + props.vibe : "vibe vibe-" + props.vibe}></div>
    )
}

export default MapPin;
